<template>
  <div class="platform-bottom-layout">
      <div class="platform-buttom-list">
        <div :class="pageType==='list'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('list')">账单列表</div>
        <div v-if="pageType==='detail'" :class="pageType==='detail'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('detail')">账单详情</div>
      </div>

      <component :is="pageType + 'Component'" ref="bottom"  />
  </div>
</template>

<script>
export default {
  components: {
    listComponent: (resolve) => {
      require(['./List'], resolve)
    },
    detailComponent: (resolve) => {
      require(['./Detail'], resolve)
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    handleChangeTag (val) {
      this.$store.commit('set_debt_pageType', val)
    }

  },
  computed: {
    pageType () {
      return this.$store.state.investmentDebt.pageType
    }
  },
  watch: {
  }
}
</script>
